import CustomSpin from '@/components/UI/CustomSpin';
import EFVideoModal from '@/components/UI/EFVideoModal';
import Image from '@/components/UI/Image';
import { EFSupportLeft } from '@/components/UI/svg/Support';
import generatorPlayUrl from '@/utils/generatorPlayUrl';
import { useGlobal } from '@/utils/useGlobal';
import css from './index.module.scss';
export default function TutorialVideos({ videoList }: any) {
    const { useLang, useState, useEffect, useRouter } = useGlobal();
    const { locale } = useRouter();
    const { t } = useLang('shared');
    const [videoModal, setVideoModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [loading, setLoading] = useState(false);

    /**
     * 点击播放视频
     * @param e
     */
    const handlePlayVideo = (e: any) => {
        const target = e.target?.closest('.mask');
        if (!target?.classList.contains('play')) return;
        const { url, isYoutube } = target.dataset;
        if (isYoutube === 'true') {
            setVideoUrl(url);
            setVideoModal(true);
        } else {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.click();
        }
    };

    return (
        <div className={css.tutorialVideo}>
            <div className="tutorial-video">
                <div className={css.tutorialVideoTitle}>
                    <EFSupportLeft className="supportIcon" />
                    <span className="font-family-semibold">{t('navigation.support.site_nav_support_videos')}</span>
                </div>
                <div className="video-list">
                    <CustomSpin loading={loading} customClass="video-spin" />
                    <ul className="videoUL" onClick={handlePlayVideo} style={{ opacity: loading ? 0 : 1 }}>
                        {videoList &&
                            videoList?.length > 0 &&
                            videoList?.map((videoItem: any) => (
                                <li className="videoItem" key={videoItem.downloadInfoId + videoItem.fileUrl}>
                                    <div className="videos">
                                        <div
                                            data-url={generatorPlayUrl(videoItem.fileUrl).url}
                                            data-is-youtube={generatorPlayUrl(videoItem.fileUrl).isYouTubeVideo}
                                            className="play mask"
                                        >
                                            <Image
                                                src={generatorPlayUrl(videoItem.fileUrl)?.imageUrl || '/img/search/2461bcec7e9c85a041ff2f95d5e476ea.png'}
                                                layout="fill"
                                                alt={videoItem.title}
                                            />
                                            <div className="play">
                                                <Image src="/icons/334711f8fbf7f8c1b02bac30b94ac1b8.svg" layout="fill" alt={t('support.download.play_icon')} />
                                            </div>
                                        </div>
                                    </div>
                                    <p data-url={videoItem.fileUrl} className="video_title">
                                        {videoItem.title}
                                    </p>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>

            <EFVideoModal visible={videoModal} source={videoUrl} onClose={() => setVideoModal(false)} playerType={locale === 'cn' ? 'Alibaba' : 'YouTube'} />
        </div>
    );
}
