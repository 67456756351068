import Image from '@/components/UI/Image';
import { EFBluetoothMobileIcon, EFConnectionMobileIcon, EFIOTModeMobileIcon } from '@/components/UI/svg/appDownload';
import { useGlobal } from '@/utils/useGlobal';
import styled from './index.module.scss';
export default function FirstSeriesInApp() {
    const { useLang, useRouter } = useGlobal();
    const { locale } = useRouter();
    const { t } = useLang('app');
    const imgMap = {
        us: ['/img/appDownload/h5/03-1.png', '/img/appDownload/h5/03-2.png', '/img/appDownload/h5/03-3.png'],
        uk: ['/img/appDownload/h5/03-1.png', '/img/appDownload/h5/03-2-uk.png', '/img/appDownload/h5/03-3-uk.png'],
        au: ['/img/appDownload/h5/03-1.png', '/img/appDownload/h5/03-2-au.png', '/img/appDownload/h5/03-3-au.png'],
        ca: ['/img/appDownload/h5/03-1.png', '/img/appDownload/h5/03-2.png', '/img/appDownload/h5/03-3.png'],
        de: ['/img/appDownload/h5/03-1-de.png', '/img/appDownload/h5/03-2-de.png', '/img/appDownload/h5/03-3-de.png'],
        fr: ['/img/appDownload/h5/03-1-fr.png', '/img/appDownload/h5/03-2-fr.png', '/img/appDownload/h5/03-3-fr.png'],
        ru: ['/img/appDownload/h5/03-1-fr.png', '/img/appDownload/h5/03-2-fr.png', '/img/appDownload/h5/03-3-fr.png'],
        cis: ['/img/appDownload/h5/03-1-fr.png', '/img/appDownload/h5/03-2-fr.png', '/img/appDownload/h5/03-3-fr.png'],
        jp: ['/img/appDownload/h5/03-1-jp.png', '/img/appDownload/h5/03-2-jp.png', '/img/appDownload/h5/03-3-jp.png'],
        cn: ['/img/appDownload/h5/03-1-cn.png', '/img/appDownload/h5/03-2-cn.png', '/img/appDownload/h5/03-3-cn.png'],
        za: ['/img/appDownload/h5/03-1.png', '/img/appDownload/h5/03-2-za.png', '/img/appDownload/h5/03-3-za.png'],
    };
    const curImg = imgMap[locale as keyof typeof imgMap] || imgMap.us;

    const scollList = [
        {
            title: t('firstSeriesInApp.title1'),
            desc: t('firstSeriesInApp.desc1'),
            imgSrc: curImg[0],
        },
        {
            title: locale === 'jp' ? t('firstSeriesInApp.title2_h5') : t('firstSeriesInApp.title2'),
            desc: t('firstSeriesInApp.desc2'),
            imgSrc: curImg[1],
        },
        {
            title: t('firstSeriesInApp.title3'),
            desc: t('firstSeriesInApp.desc3'),
            imgSrc: curImg[2],
            iconList: [
                {
                    icon: <EFIOTModeMobileIcon />,
                    text: t('firstSeriesInApp.iotMode'),
                },
                {
                    icon: <EFConnectionMobileIcon />,
                    text: t('firstSeriesInApp.directConnection'),
                },
                {
                    icon: <EFBluetoothMobileIcon />,
                    text: t('firstSeriesInApp.bluetooth'),
                },
            ],
        },
    ];

    return (
        <div className={`${styled.firstSeriesInAppH5}`}>
            <div className="first-series">
                <div className="top_title">{t('firstSeriesInApp.topTitle')}</div>
                {scollList.map((item, index) => (
                    <div className="scoll_item" key={index}>
                        <h6 className="title">{item.title}</h6>
                        <p className="desc">{item.desc}</p>
                        <div className="icon_box">
                            {item.iconList
                                ? item.iconList.map((iconItem, iconIndex) => (
                                      <div className="icon_item" key={`icon${iconIndex}`}>
                                          {iconItem.icon}
                                          <span className={`icon-text icon-text${iconIndex + 1}`}>{iconItem.text}</span>
                                      </div>
                                  ))
                                : ''}
                        </div>
                        <div className="img_box">
                            <Image alt={t('item.title')} src={item.imgSrc} className="fullImgs" layout="fill" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
